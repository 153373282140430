import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Importer Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyBBqcTSZHytsL9MadC32CbDZqlVN-OpOew",
  authDomain: "annecymeteo-49ea3.firebaseapp.com",
  projectId: "annecymeteo-49ea3",
  storageBucket: "annecymeteo-49ea3.appspot.com",
  messagingSenderId: "126177561925",
  appId: "1:126177561925:web:a6eeeec9141e9b2399022c",
  measurementId: "G-754Q3G6CED"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialiser Firebase Storage

// Ajouter la persistance locale
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence locale activée");
  })
  .catch((error) => {
    console.error("Erreur lors de la configuration de la persistance :", error);
  });

export { auth, db, storage }; // Exporter Firebase Storage