import React, { useState, useEffect } from 'react';
import './Bandeaux.css';
import Header from './Header';
import { db, storage } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const Bandeau = () => {
  const [isActive, setIsActive] = useState(false);
  const [title, setTitle] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [bannerType, setBannerType] = useState('information');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [alertInProgress, setAlertInProgress] = useState(false);

  useEffect(() => {
    // Récupérer le bandeau actuel depuis Firestore
    const fetchCurrentBanner = async () => {
      const bannerRef = doc(db, 'banners', 'currentBanner');
      const bannerSnap = await getDoc(bannerRef);

      if (bannerSnap.exists()) {
        const bannerData = bannerSnap.data();
        const now = new Date();

        if (new Date(bannerData.endDate.toDate()) > now) {
          // Si une alerte est en cours, pré-remplir les champs
          setIsActive(bannerData.isActive);
          setTitle(bannerData.title);
          setBodyText(bannerData.bodyText);
          setStartDate(bannerData.startDate.toDate().toISOString().slice(0, 16));
          setEndDate(bannerData.endDate.toDate().toISOString().slice(0, 16));
          setBannerType(bannerData.bannerType);
          setAlertInProgress(true); // Alerte en cours
        }
      }
    };

    fetchCurrentBanner();
  }, []);

  useEffect(() => {
    const checkEndDate = () => {
      const now = new Date();
      if (endDate && new Date(endDate) <= now) {
        // Si la date de fin est atteinte, on met isActive à false
        setIsActive(false);
        setAlertInProgress(false);
      }
    };

    // Vérifier toutes les minutes si la date de fin est atteinte
    const intervalId = setInterval(checkEndDate, 60000);

    // Nettoyage de l'intervalle quand le composant est démonté
    return () => clearInterval(intervalId);
  }, [endDate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      let imageUrl = '';

      if (imageFile) {
        const imageRef = ref(storage, `banners/${uuidv4()}`);
        await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      const updatedEndDate = !isActive && alertInProgress ? new Date() : new Date(endDate);

      const bannerData = {
        isActive,
        title,
        bodyText,
        startDate: new Date(startDate),
        endDate: updatedEndDate,
        bannerType,
        imageUrl,
      };

      await setDoc(doc(db, 'banners', 'currentBanner'), bannerData);

      console.log('Bandeau d\'alerte mis à jour :', bannerData);

      if (!isActive && alertInProgress) {
        // Si l'alerte est désactivée manuellement, on la marque comme terminée
        setAlertInProgress(false);
      }

      if (new Date(endDate) > new Date()) {
        alert('Le bandeau a été mis à jour et reste actif.');
      } else {
        // Réinitialiser les champs si l'alerte est terminée
        setIsActive(false);
        setTitle('');
        setBodyText('');
        setStartDate('');
        setEndDate('');
        setBannerType('information');
        setImageFile(null);
        alert('Le bandeau a été publié avec succès.');
      }
    } catch (error) {
      console.error('Erreur lors de la publication du bandeau :', error);
      alert('Une erreur est survenue lors de la publication du bandeau.');
    }
  };

  return (
    <div>
      <Header />

      <div className="bandeau-container">
        <h2>Gestion du Bandeau</h2>

        {/* Toggle pour activer/désactiver le bandeau */}
        <label className="bandeau-switch">
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
          <span className="bandeau-slider-round"></span>
        </label>
        <span className="bandeau-switch-label">Activer le bandeau</span>

        {/* Si le bandeau n'est pas actif, on masque ou désactive les autres champs */}
        <form onSubmit={handleSubmit}>
          {isActive && (
            <>
              {/* Titre */}
              <input
                type="text"
                className="bandeau-alert-input"
                placeholder="Titre du bandeau"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />

              {/* Corps du texte */}
              <textarea
                className="bandeau-alert-textarea"
                placeholder="Corps du texte"
                value={bodyText}
                onChange={(e) => setBodyText(e.target.value)}
                required
              ></textarea>

              {/* Dates de début et de fin */}
              <label>
                Date de début :
                <input
                  type="datetime-local"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
              </label>

              <label>
                Date de fin :
                <input
                  type="datetime-local"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                />
              </label>

              {/* Type de bandeau */}
              <label>
                Type de bandeau :
                <select
                  value={bannerType}
                  onChange={(e) => setBannerType(e.target.value)}
                  required
                >
                  <option value="information">Information</option>
                  <option value="alerte-orange">Alerte Orange</option>
                  <option value="alerte-rouge">Alerte Rouge</option>
                </select>
              </label>

              {/* Upload d'image */}
              <label>
                Image associée :
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setImageFile(e.target.files[0]);
                    }
                  }}
                />
              </label>
            </>
          )}

          {/* Bouton pour publier le bandeau */}
          <button type="submit" className="bandeau-submit-button">
            Publier le bandeau
          </button>
        </form>
      </div>
    </div>
  );
};

export default Bandeau;