import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { getWeatherIcon } from './utils/getWeatherIcon';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import Header from './Header';

import './Previsions.css';

// Icônes dans le sidepanel
const ClearIcon = `${process.env.PUBLIC_URL}/assets/icons/day/clear.svg`;
const Cloudy2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_2.svg`;
const Cloudy3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_3.svg`;
const Cloudy4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_4.svg`;
const Cloudy5Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_5.svg`;
const Cloudy6Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_6.svg`;
const Cirrus4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_4.svg`;
const Rain1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_1.svg`;
const Rain2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_2.svg`;
const Rain3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_3.svg`;
const Rain4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_4.svg`;
const IceRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_1.svg`;
const IceRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_2.svg`;
const IceRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_3.svg`;
const Snow1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_1.svg`;
const Snow3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_3.svg`;
const Snow4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_4.svg`;
const RainShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_2.svg`;
const RainShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_3.svg`;
const RainShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_4.svg`;
const SnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_2.svg`;
const SnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_3.svg`;
const StormShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_2.svg`;
const StormShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_3.svg`;
const StormShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_4.svg`;

// Ajoutez ici toutes les autres icônes nécessaires
const icons = [
  ClearIcon,
  Cloudy2Icon,
  Cloudy3Icon,
  Cloudy4Icon,
  Cloudy5Icon,
  Cloudy6Icon,
  Cirrus4Icon,
  Rain1Icon,
  Rain2Icon,
  Rain3Icon,
  Rain4Icon,
  IceRain1Icon,
  IceRain2Icon,
  IceRain3Icon,
  Snow1Icon,
  Snow3Icon,
  Snow4Icon,
  RainShower2Icon,
  RainShower3Icon,
  RainShower4Icon,
  SnowShower2Icon,
  SnowShower3Icon,
  StormShower2Icon,
  StormShower3Icon,
  StormShower4Icon
];

type ReliabilityType = 'bonne' | 'moyenne' | 'mauvaise';

interface PeriodData {
  temperature: number;
  windGust: number;
  icon: string;
}

interface ForecastData {
  morningData?: PeriodData;
  afternoonData?: PeriodData;
  eveningData?: PeriodData;
  nightData?: PeriodData;
  bulletin?: string; // Champ pour le bulletin
  alertOrange?: boolean; // Propriété pour l'alerte orange
  alertRed?: boolean; // Propriété pour l'alerte rouge
  reliability?: ReliabilityType; // Propriété pour la fiabilité
  sunrise?: string; // Heure du lever du soleil
  sunset?: string; // Heure du coucher du soleil
}

// Définir un type pour les périodes valides
type ForecastPeriod = 'morningData' | 'afternoonData' | 'eveningData' | 'nightData';

// Fonction pour obtenir le mode (la valeur la plus fréquente)
const mode = (arr: any[]) => {
  const counts: { [key: string]: number } = {};
  let maxCount = 0;
  let modeValue = arr[0];

  arr.forEach((val) => {
    counts[val] = (counts[val] || 0) + 1;
    if (counts[val] > maxCount) {
      maxCount = counts[val];
      modeValue = val;
    }
  });

  return modeValue;
};

// Fonction pour obtenir les données d'une période
const getPeriodData = (
  data: any[],
  startHour: number,
  endHour: number,
  type: 'min' | 'max' | 'avg',
  isNight: boolean
): PeriodData => {
  const filtered = data.filter((hourly: any) => {
    const hour = new Date(hourly.forecastStart).getHours();
    return hour >= startHour && hour <= endHour;
  });

  // Fournir des valeurs par défaut si aucune donnée n'est disponible
  if (filtered.length === 0) {
    return {
      temperature: 0,
      windGust: 0,
      icon: getWeatherIcon(0, isNight),
    };
  }

  const temperatures = filtered.map((hourly: any) => hourly.temperature);

  // Choisir min, max, ou avg selon le type demandé
  let temperature: number;
  if (type === 'min') {
    temperature = Math.min(...temperatures);
  } else if (type === 'max') {
    temperature = Math.max(...temperatures);
  } else {
    temperature =
      temperatures.reduce((sum: number, temp: number) => sum + temp, 0) /
      temperatures.length;
  }

  const windGusts = filtered.map((hourly: any) => hourly.windGust);
  const maxWindGust = Math.max(...windGusts);

  const weatherCodes = filtered.map((hourly: any) => hourly.weatherCode);
  const weatherCode = mode(weatherCodes);

  // Déterminer l'icône basée sur le weatherCode
  const icon = getWeatherIcon(weatherCode, isNight);

  return {
    temperature: Math.round(temperature),
    windGust: Math.round(maxWindGust / 5) * 5,
    icon,
  };
};

// Composant Principal
const Previsions: React.FC = () => {
  const [weatherData, setWeatherData] = useState<any>(null);
  const [expertForecasts, setExpertForecasts] = useState<
    Record<string, ForecastData>
  >({});
  const [sidePanelOpen, setSidePanelOpen] = useState<boolean>(false);
  const [editingDay, setEditingDay] = useState<{
    day: string;
    period: ForecastPeriod;
  } | null>(null);

  // Nouveaux états pour le loader et les messages
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

  // Nouveaux états pour le décalage manuel
  const [shiftLoading, setShiftLoading] = useState<boolean>(false);
  const [shiftMessage, setShiftMessage] = useState<string | null>(null);
  const [shiftMessageType, setShiftMessageType] = useState<'success' | 'error' | null>(null);

  const sidePanelRef = useRef<HTMLDivElement>(null);

  // Fonction pour fermer le sidepanel lorsque vous cliquez en dehors
  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidePanelRef.current &&
      !sidePanelRef.current.contains(event.target as Node)
    ) {
      setSidePanelOpen(false);
    }
  };

  // Ajouter et retirer l'écouteur d'événements lors du montage et du démontage
  useEffect(() => {
    if (sidePanelOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidePanelOpen]);

  // Effet pour masquer le message après 5 secondes
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  // Effet pour masquer le message de décalage après 5 secondes
  useEffect(() => {
    if (shiftMessage) {
      const timer = setTimeout(() => {
        setShiftMessage(null);
        setShiftMessageType(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [shiftMessage]);

  // Effet pour récupérer les données météo
  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(
          'https://api.open-meteo.com/v1/forecast?latitude=45.9088&longitude=6.1257&current_weather=true&hourly=temperature_2m,weathercode,windgusts_10m,is_day&daily=sunrise,sunset&timezone=Europe%2FBerlin&forecast_days=14&models=best_match'
        );
        const transformedData = {
          forecastDaily: {
            days: response.data.daily.sunrise.map(
              (sunrise: string, index: number) => ({
                forecastStart: response.data.daily.sunrise[index],
                forecastEnd: response.data.daily.sunset[index],
                sunrise: response.data.daily.sunrise[index],
                sunset: response.data.daily.sunset[index],
              })
            ),
          },
          forecastHourly: {
            hours: response.data.hourly.time.map((time: string, index: number) => ({
              forecastStart: time,
              temperature: response.data.hourly.temperature_2m[index],
              windGust: response.data.hourly.windgusts_10m[index],
              weatherCode: response.data.hourly.weathercode[index],
            })),
          },
        };
        setWeatherData(transformedData);
      } catch (error) {
        console.error('Erreur lors de la récupération des données météo:', error);
      }
    };
    fetchWeatherData();
  }, []);

  // Effet pour récupérer les prévisions sauvegardées
  useEffect(() => {
    const fetchSavedForecasts = async () => {
      const docRef = doc(db, 'forecasts', 'expertForecasts');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setExpertForecasts(docSnap.data() as Record<string, ForecastData>);
      } else {
        console.log('Aucune prévision sauvegardée trouvée!');
      }
    };

    fetchSavedForecasts();
  }, []);

  const openSidePanel = (dayNumber: string, period: ForecastPeriod) => {
    setEditingDay({ day: dayNumber, period });
    setSidePanelOpen(true);
  };

  const updateExpertForecast = (dayNumber: string, updatedForecast: ForecastData) => {
    setExpertForecasts((prevForecasts) => ({
      ...prevForecasts,
      [dayNumber]: {
        ...prevForecasts[dayNumber],
        ...updatedForecast,
        sunrise: updatedForecast.sunrise || prevForecasts[dayNumber]?.sunrise,
        sunset: updatedForecast.sunset || prevForecasts[dayNumber]?.sunset,
      },
    }));
  };

  const updateExpertForecastForPeriod = (
    dayNumber: string,
    period: ForecastPeriod,
    updatedData: PeriodData
  ) => {
    setExpertForecasts((prevForecasts) => {
      const dayKey = `${dayNumber}-expert`;
      const existingDayData = prevForecasts[dayKey] || {
        morningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
        afternoonData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
        eveningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
        nightData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
      };

      return {
        ...prevForecasts,
        [dayKey]: {
          ...existingDayData,
          [period]: updatedData,
        },
      };
    });
  };

  const resetExpertForecastForDay = (dayNumber: string) => {
    const dayIndex = parseInt(dayNumber.substring(1), 10);
  
    if (
      !weatherData ||
      !weatherData.forecastDaily ||
      !weatherData.forecastDaily.days[dayIndex]
    ) {
      console.error(`Aucune donnée disponible pour le jour ${dayNumber}`);
      return;
    }
  
    const apiDay = weatherData.forecastDaily.days[dayIndex];
  
    const apiHourly = weatherData.forecastHourly.hours.filter((hour: any) => {
      const forecastTime = new Date(hour.forecastStart).getTime();
      const dayStart = new Date(apiDay.forecastStart).setHours(0, 0, 0, 0);
      const dayEnd = new Date(apiDay.forecastEnd).setHours(23, 59, 59, 999);
      return forecastTime >= dayStart && forecastTime <= dayEnd;
    });
  
    if (!apiHourly || apiHourly.length === 0) {
      console.error(`Aucune donnée horaire disponible pour le jour ${dayNumber}`);
      return;
    }
  
    // Mettre à jour uniquement les prévisions (pictos, températures, vents)
    const newExpertForecast: Partial<ForecastData> = {
      morningData: getPeriodData(apiHourly, 6, 11, 'min', false),
      afternoonData: getPeriodData(apiHourly, 12, 17, 'max', false),
      eveningData: getPeriodData(apiHourly, 18, 23, 'avg', false),
      // Pour la nuit, récupérer les données du lendemain
      nightData: getPeriodData(
        weatherData.forecastHourly.hours.filter((hour: any) => {
          const forecastTime = new Date(hour.forecastStart).getTime();
          const nextDay = new Date(apiDay.forecastStart);
          nextDay.setDate(nextDay.getDate() + 1);
          const dayStart = new Date(nextDay).setHours(0, 0, 0, 0);
          const dayEnd = new Date(nextDay).setHours(5, 59, 59, 999);
          return forecastTime >= dayStart && forecastTime <= dayEnd;
        }),
        0,
        5,
        'avg',
        true
      ),
      sunrise: apiDay.sunrise,
      sunset: apiDay.sunset,
    };
  
    setExpertForecasts((prevForecasts) => ({
      ...prevForecasts,
      [`${dayNumber}-expert`]: {
        ...prevForecasts[`${dayNumber}-expert`],
        morningData: newExpertForecast.morningData,
        afternoonData: newExpertForecast.afternoonData,
        eveningData: newExpertForecast.eveningData,
        nightData: newExpertForecast.nightData,
        sunrise: newExpertForecast.sunrise,
        sunset: newExpertForecast.sunset,
      },
    }));
  };

  const saveForecasts = async () => {
    setLoading(true);
    setMessage(null);
    setMessageType(null);
    try {
      await setDoc(doc(db, 'forecasts', 'expertForecasts'), expertForecasts);
      setMessage('Prévisions mises à jour avec succès !');
      setMessageType('success');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des prévisions:', error);
      setMessage('Erreur lors de la mise à jour des prévisions.');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  const handleManualShift = async () => {
    setShiftLoading(true);
    setShiftMessage(null);
    setShiftMessageType(null);

    try {
      // Appeler la fonction Cloud via HTTP
      await axios.get('https://us-central1-annecymeteo-49ea3.cloudfunctions.net/shiftForecastsManual');
      setShiftMessage('Décalage des prévisions effectué avec succès.');
      setShiftMessageType('success');

      // Mettre à jour les prévisions depuis Firestore
      const docRef = doc(db, 'forecasts', 'expertForecasts');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setExpertForecasts(docSnap.data() as Record<string, ForecastData>);
      } else {
        console.log('Aucune prévision sauvegardée trouvée!');
      }
    } catch (error) {
      console.error('Erreur lors du décalage manuel des prévisions:', error);
      setShiftMessage('Erreur lors du décalage des prévisions.');
      setShiftMessageType('error');
    } finally {
      setShiftLoading(false);
    }
  };

  if (!weatherData) {
    return <div>Chargement des données météo...</div>;
  }

  const { forecastDaily, forecastHourly } = weatherData;

  const currentDate = new Date().setHours(0, 0, 0, 0);

  const filteredDays = forecastDaily.days.filter((day: any) => {
    const dayDate = new Date(day.forecastStart).setHours(0, 0, 0, 0);
    return dayDate >= currentDate;
  });

  const startDate = new Date(filteredDays[0].forecastStart);
  const fullDays = [...filteredDays];

  while (fullDays.length < 10) {
    const lastDay = fullDays[fullDays.length - 1];
    const nextDay = new Date(lastDay.forecastStart);
    nextDay.setDate(nextDay.getDate() + 1);

    fullDays.push({
      forecastStart: nextDay.toISOString(),
      forecastEnd: nextDay.toISOString(),
    });
  }

  const renderDayPart = (
    label: string,
    data: PeriodData | undefined,
    isNight: boolean,
    isExpertised: boolean,
    onIconClick?: () => void,
    onDataChange?: (updatedData: PeriodData) => void
  ) => {
    if (!data) {
      return (
        <div className="day-part">
          <h4>{label}</h4>
          <p>Données non disponibles</p>
        </div>
      );
    }

    const icon = data.icon;

    // Arrondir les valeurs pour l'affichage
    const roundedTemperature = Math.round(data.temperature);
    const roundedWindGust = Math.round(data.windGust / 5) * 5;

    return (
      <div className="day-part">
        <h4>{label}</h4>

        {/* Le sidepanel s'ouvre uniquement lorsque l'utilisateur clique sur l'icône */}
        <img
          src={icon}
          alt={icon}
          className="weather-icon"
          onClick={onIconClick}
        />

        {isExpertised && (
          <>
            <div className="input-label-group">
              <input
                type="number"
                value={roundedTemperature}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const parsedValue = parseFloat(inputValue);
                  if (!isNaN(parsedValue)) {
                    const newTemp = Math.round(parsedValue);
                    onDataChange && onDataChange({ ...data, temperature: newTemp });
                  } else {
                    onDataChange && onDataChange({ ...data, temperature: 0 });
                  }
                }}
                className="input-temperature"
              />
            </div>
            <div className="input-label-group">
              <input
                type="number"
                value={roundedWindGust}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const parsedValue = parseFloat(inputValue);
                  if (!isNaN(parsedValue)) {
                    const newWindGust = Math.round(parsedValue / 5) * 5;
                    onDataChange && onDataChange({ ...data, windGust: newWindGust });
                  } else {
                    onDataChange && onDataChange({ ...data, windGust: 0 });
                  }
                }}
                className="input-wind"
              />
            </div>
          </>
        )}
        {!isExpertised && (
          <>
            <p>{roundedTemperature}°</p>
            <p>Rafales: <br /> {roundedWindGust} km/h</p>
          </>
        )}
      </div>
    );
  };

  const renderDay = (
    day: any,
    index: number,
    hourlyData: any[],
    startDate: Date,
    isExpertised: boolean,
    expertForecasts: Record<string, ForecastData>,
    updateExpertForecast: (dayNumber: string, updatedForecast: ForecastData) => void,
    resetExpertForecastForDay: (dayNumber: string) => void,
    openSidePanel: (dayNumber: string, period: ForecastPeriod) => void,
    nextDayNightHours: any[] // Nouveau paramètre
  ) => {
    const dayStart = new Date(day.forecastStart).setHours(0, 0, 0, 0);
    const dayEnd = new Date(day.forecastStart).setHours(23, 59, 59, 999);

    const hoursForDay = hourlyData.filter((hour: any) => {
      const forecastTime = new Date(hour.forecastStart).getTime();
      return forecastTime >= dayStart && forecastTime <= dayEnd;
    });

    if (!hoursForDay || hoursForDay.length === 0) {
      return (
        <div key={index} className="day-block">
          <h3>
            Jour {index + 1} - {new Date(day.forecastStart).toLocaleDateString()}
          </h3>
          <p>Données horaires non disponibles pour ce jour.</p>
        </div>
      );
    }

    // Données de la journée
    const morningData = getPeriodData(hoursForDay, 6, 11, 'min', false);
    const afternoonData = getPeriodData(hoursForDay, 12, 17, 'max', false);
    const eveningData = getPeriodData(hoursForDay, 18, 23, 'avg', false);

    // Utiliser les données de nuit du lendemain
    const nightData = nextDayNightHours.length > 0
      ? getPeriodData(nextDayNightHours, 0, 5, 'avg', true)
      : {
          temperature: 0,
          windGust: 0,
          icon: getWeatherIcon(0, true),
        };

    const daysDifference = Math.floor(
      (new Date(day.forecastStart).getTime() - startDate.getTime()) /
        (1000 * 60 * 60 * 24)
    );
    const dayNumber = `J${daysDifference}`;

    const forecastKey = `${dayNumber}-expert`;
    const expertData = expertForecasts[forecastKey] || {};

    const forecastData: ForecastData = {
      morningData: isExpertised
        ? { ...morningData, ...expertData.morningData }
        : morningData,
      afternoonData: isExpertised
        ? { ...afternoonData, ...expertData.afternoonData }
        : afternoonData,
      eveningData: isExpertised
        ? { ...eveningData, ...expertData.eveningData }
        : eveningData,
      nightData: isExpertised
        ? { ...nightData, ...expertData.nightData }
        : nightData,
      bulletin: isExpertised ? expertData.bulletin || '' : '',
      alertOrange: isExpertised ? expertData.alertOrange || false : false,
      alertRed: isExpertised ? expertData.alertRed || false : false,
      reliability: isExpertised ? expertData.reliability || 'bonne' : undefined,
      sunrise: expertData.sunrise || day.sunrise, // Assurez-vous que sunrise est inclus
      sunset: expertData.sunset || day.sunset,   // Assurez-vous que sunset est inclus
    };

    return (
      <div
        key={index}
        className={`day-block ${isExpertised ? 'expert-block' : ''} ${
          forecastData.alertOrange ? 'alert-orange' : ''
        } ${forecastData.alertRed ? 'alert-red' : ''}`}
      >
        <h3>
          {dayNumber} -{' '}
          {new Date(day.forecastStart).toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </h3>

        {isExpertised && (
          <div className="alert-switches">
            {/* Switch pour l'Alerte Orange */}
            <label className="switch switch-orange">
              <input
                type="checkbox"
                checked={forecastData.alertOrange || false}
                onChange={() => {
                  setExpertForecasts((prevForecasts) => {
                    const dayKey = `${dayNumber}-expert`;
                    const existingDayData = prevForecasts[dayKey] || {
                      morningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                      afternoonData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                      eveningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                      nightData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                      bulletin: '',
                      alertOrange: false,
                      alertRed: false,
                      sunrise: day.sunrise, // Initialiser sunrise
                      sunset: day.sunset,   // Initialiser sunset
                    };
                    return {
                      ...prevForecasts,
                      [dayKey]: {
                        ...existingDayData,
                        alertOrange: !forecastData.alertOrange,
                      },
                    };
                  });
                }}
              />
              <span className="slider round"></span>
            </label>
              
            {/* Switch pour l'Alerte Rouge */}
            <label className="switch switch-red">
              <input
                type="checkbox"
                checked={forecastData.alertRed || false}
                onChange={() => {
                  setExpertForecasts((prevForecasts) => {
                    const dayKey = `${dayNumber}-expert`;
                    const existingDayData = prevForecasts[dayKey] || {
                      morningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                      afternoonData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                      eveningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                      nightData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                      bulletin: '',
                      alertOrange: false,
                    };
                    return {
                      ...prevForecasts,
                      [dayKey]: {
                        ...existingDayData,
                        alertRed: !forecastData.alertRed,
                      },
                    };
                  });
                }}
              />
              <span className="slider round"></span>
            </label>

            {/* Sélecteur de fiabilité */}
            <div className="reliability-selector">
              <label>
                Fiabilité :
                <select
                  value={forecastData.reliability || 'bonne'}
                  onChange={(e) => {
                    const selectedReliability = e.target.value as ReliabilityType;
                    setExpertForecasts((prevForecasts) => {
                      const dayKey = `${dayNumber}-expert`;
                      const existingDayData = prevForecasts[dayKey] || {
                        morningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                        afternoonData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                        eveningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                        nightData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                        bulletin: '',
                        alertOrange: false,
                        alertRed: false,
                      };
                      return {
                        ...prevForecasts,
                        [dayKey]: {
                          ...existingDayData,
                          reliability: selectedReliability,
                        },
                      };
                    });
                  }}
                >
                  <option value="bonne">Bonne</option>
                  <option value="moyenne">Moyenne</option>
                  <option value="mauvaise">Mauvaise</option>
                </select>
              </label>
            </div>
          </div>
        )}

        <div className="prevision">
          {renderDayPart(
            'Matin',
            forecastData.morningData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'morningData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                  updateExpertForecastForPeriod(dayNumber, 'morningData', updatedData)
              : undefined
          )}

          {renderDayPart(
            'Aprèm',
            forecastData.afternoonData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'afternoonData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                  updateExpertForecastForPeriod(dayNumber, 'afternoonData', updatedData)
              : undefined
          )}
          {renderDayPart(
            'Soirée',
            forecastData.eveningData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'eveningData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                  updateExpertForecastForPeriod(dayNumber, 'eveningData', updatedData)
              : undefined
          )}

          {renderDayPart(
            'Nuit',
            forecastData.nightData,
            true,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'nightData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                  updateExpertForecastForPeriod(dayNumber, 'nightData', updatedData)
              : undefined
          )}
        </div>

        {isExpertised && (
          <>
            <textarea
              value={forecastData.bulletin || ''}
              onChange={(e) => {
                const newBulletin = e.target.value;
                setExpertForecasts((prevForecasts) => {
                  const dayKey = `${dayNumber}-expert`;
                  const existingDayData = prevForecasts[dayKey] || {
                    morningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                    afternoonData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                    eveningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                    nightData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                    bulletin: '',
                    alertOrange: false,
                    alertRed: false,
                  };
                  return {
                    ...prevForecasts,
                    [dayKey]: {
                      ...existingDayData,
                      bulletin: newBulletin,
                    },
                  };
                });
              }}
              placeholder="Écrire le bulletin pour ce jour"
            />
            <button onClick={() => resetExpertForecastForDay(dayNumber)}>
              Réinitialiser avec API
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="prevision-page">
      {/* Ajout du header */}
      <Header />

      <div className="prevision-container">
        {fullDays.map((day: any, index: number) => {
          // Obtenir le lendemain
          const nextDay = fullDays[index + 1];

          // Filtrer les heures du lendemain entre 00h et 05h59
          const nextDayNightHours = nextDay
            ? forecastHourly.hours.filter((hour: any) => {
                const forecastTime = new Date(hour.forecastStart);
                const nextDayDate = new Date(nextDay.forecastStart);
                return (
                  forecastTime.getFullYear() === nextDayDate.getFullYear() &&
                  forecastTime.getMonth() === nextDayDate.getMonth() &&
                  forecastTime.getDate() === nextDayDate.getDate() &&
                  forecastTime.getHours() >= 0 &&
                  forecastTime.getHours() < 6
                );
              })
            : []; // Si pas de lendemain, tableau vide ou données par défaut

          return (
            <div key={index} className="combined-day-block">
              {renderDay(
                day,
                index,
                forecastHourly.hours,
                startDate,
                false,
                expertForecasts,
                updateExpertForecast,
                resetExpertForecastForDay,
                openSidePanel,
                nextDayNightHours // Passer les heures de nuit du lendemain
              )}
              {renderDay(
                day,
                index,
                forecastHourly.hours,
                startDate,
                true,
                expertForecasts,
                updateExpertForecast,
                resetExpertForecastForDay,
                openSidePanel,
                nextDayNightHours // Passer les heures de nuit du lendemain
              )}
            </div>
          );
        })}

        {sidePanelOpen && (
          <div ref={sidePanelRef} className="side-panel">
            <h3>Choisir une icône</h3>
            <div className="icon-grid">
              {icons.map((iconPath, index) => (
                <img
                  key={index}
                  src={iconPath}
                  alt={`Icon ${index}`}
                  className="icon-choice"
                  onClick={() => {
                    if (editingDay) {
                      setExpertForecasts((prev) => {
                        const { day, period } = editingDay;
                        const dayKey = `${day}-expert`;

                        const existingDayData = prev[dayKey] || {
                          morningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                          afternoonData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                          eveningData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                          nightData: { temperature: 0, windGust: 0, icon: getWeatherIcon(0, false) },
                          bulletin: '',
                          alertOrange: false,
                          alertRed: false,
                        };

                        const existingPeriodData: PeriodData = existingDayData[period] || {
                          temperature: 0,
                          windGust: 0,
                          icon: getWeatherIcon(0, false),
                        };

                        const updatedPeriodData: PeriodData = {
                          ...existingPeriodData,
                          icon: iconPath,
                        };

                        return {
                          ...prev,
                          [dayKey]: {
                            ...existingDayData,
                            [period]: updatedPeriodData,
                          },
                        };
                      });
                    }
                    setSidePanelOpen(false);
                  }}
                />
              ))}
            </div>
          </div>
        )}

        {/* Bouton de décalage manuel */}
        <div className="manual-shift-button">
          <button onClick={handleManualShift} disabled={shiftLoading}>
            {shiftLoading ? (
              <div className="loader"></div> /* Loader */
            ) : (
              'Forcer le décalage des prévisions'
            )}
          </button>
        </div>

        {/* Bouton avec loader */}
        <div className="bandeau-floating-button">
          <button className="floating-button" onClick={saveForecasts} disabled={loading}>
            {loading ? (
              <div className="loader"></div> /* Loader */
            ) : (
              'Publier les prévisions'
            )}
          </button>
        </div>

        {/* Bandeau de message pour le décalage manuel */}
        {shiftMessage && <div className={`message-banner ${shiftMessageType}`}>{shiftMessage}</div>}

        {/* Bandeau de message */}
        {message && <div className={`message-banner ${messageType}`}>{message}</div>}
      </div>
    </div>
  );
};

export default Previsions;