import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';  // Import de la fonction signOut
import { auth } from './firebase';  // Import de la configuration Firebase

const AdminHome = () => {
  // Fonction de déconnexion
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("Utilisateur déconnecté");
        window.location.href = '/';  // Rediriger vers la page de connexion après déconnexion
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion :", error);
      });
  };

  return (
    <div>
      <h1>Bienvenue sur le Back-office AnnecyMeteo</h1>
      <nav>
        <ul>
          <li><Link to="/previsions">Gestion des Prévisions</Link></li>
          <li><Link to="/bandeau">Gestion du Bandeau</Link></li>
          <li><Link to="/notifications">Gestion des Notifications</Link></li>
        </ul>
      </nav>
      <button onClick={handleLogout}>Déconnexion</button> {/* Bouton de déconnexion */}
    </div>
  );
};

export default AdminHome;