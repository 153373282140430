import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Previsions from './Previsions';
import Bandeaux from './Bandeaux';
import Notifications from './Notifications';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { onAuthStateChanged } from 'firebase/auth';
import AdminHome from './adminhome';  // Import de AdminHome

const App = () => {
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Utilisateur toujours connecté :", user);
      } else {
        console.log("Aucun utilisateur connecté.");
      }
    });

    return () => unsubscribe(); 
  }, []);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Navigate to="/admin" /> : <Login />} />
        <Route path="/admin" element={user ? <AdminHome /> : <Navigate to="/" />} />
        <Route path="/previsions" element={<Previsions />} />
        <Route path="/bandeaux" element={<Bandeaux />} /> {/* Route correcte pour Bandeaux */}
        <Route path="/notifications" element={<Notifications />} />
      </Routes>
    </Router>
  );
};

export default App;