import React, { useState } from 'react';
import './Notifications.css'; // Assure-toi de créer un fichier CSS pour le style
import Header from './Header'; // Intégration du Header
import { db } from './firebase'; // Assure-toi que Firebase Cloud Messaging est configuré dans ton projet Firebase
import { doc, setDoc } from 'firebase/firestore';

const Notifications = () => {
  const [notificationMessage, setNotificationMessage] = useState('');
  const [title, setTitle] = useState('');
  const [sendDate, setSendDate] = useState('');
  const [isScheduled, setIsScheduled] = useState(false);

  const handleSendNotification = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const notificationData = {
        title,
        message: notificationMessage,
        sendDate: isScheduled ? new Date(sendDate) : new Date(),
        isScheduled,
      };

      // Logique pour envoyer ou programmer la notification push via Firebase Cloud Messaging
      await setDoc(doc(db, 'notifications', 'currentNotification'), notificationData);

      console.log("Notification envoyée :", notificationData);

      // Réinitialiser le formulaire après envoi
      setTitle('');
      setNotificationMessage('');
      setSendDate('');
      setIsScheduled(false);

      alert('Notification envoyée avec succès.');
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la notification :', error);
      alert('Une erreur est survenue lors de l\'envoi de la notification.');
    }
  };

  return (
    <div>
      <Header />

      <div className="notifications-container">
        <h2>Gestion des Notifications</h2>
        <form onSubmit={handleSendNotification}>
          
          {/* Titre de la notification */}
          <input
            type="text"
            className="notifications-input"
            placeholder="Titre de la notification"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          {/* Message de la notification */}
          <textarea
            className="notifications-textarea"
            placeholder="Message de notification"
            value={notificationMessage}
            onChange={(e) => setNotificationMessage(e.target.value)}
            required
          ></textarea>

          {/* Toggle pour planifier la notification */}
          <label className="notifications-switch">
            <input
              type="checkbox"
              checked={isScheduled}
              onChange={() => setIsScheduled(!isScheduled)}
            />
            <span className="notifications-slider-round"></span>
          </label>
          <span className="notifications-switch-label">Planifier l'envoi</span>

          {/* Date de programmation */}
          {isScheduled && (
            <input
              type="datetime-local"
              className="notifications-datetime"
              value={sendDate}
              onChange={(e) => setSendDate(e.target.value)}
              required
            />
          )}

          <button type="submit" className="notifications-submit-button">
            Envoyer la notification
          </button>
        </form>
      </div>
    </div>
  );
};

export default Notifications;